import Plausible from 'plausible-tracker'

function initTracking () {
  // Don't track locally
  const isLocal = process.env.VUE_APP_CAPLENA_ENV === 'local'
  const isPrerendering = window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.IS_PRERENDERING
  // Don't track if plausible disable tracking is set
  const noTracking = window.localStorage.plausible_ignore === 'true'
  const doTracking = !noTracking && !isLocal && !isPrerendering
  if (doTracking) {
    const plausible = Plausible({
      apiHost: window.location.origin // proxied through our nginx FE deployment to plausible
    })
    plausible.enableAutoPageviews()
  }
}

export { initTracking }
