const INTERCOM_APP_IDS = {
  'test': 'tj2c3k8q',
  'prod': 'uwrovuel'
}

export default {
  data () {
    return {
    }
  },

  methods: {
    loadIntercom (mode) {
      if (this.nonUserMode) return

      let i = function () { i.c(arguments) }
      i.q = []
      i.c = function (args) { i.q.push(args) }
      window.Intercom = i

      var s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://js.intercomcdn.com/shim.latest.js'
      s.onload = this.intercomLoaded
      document.body.appendChild(s)
    },

    intercomLoaded ($event) {
      let intercomSettings = {
        app_id: INTERCOM_APP_IDS[process.env.VUE_APP_CAPLENA_ENV === 'local' ? 'test' : 'prod'],
        language_override: this.$i18n.locale
      }

      if (this.user.id) {
        intercomSettings = {
          ...intercomSettings,
          user_id: `${process.env.VUE_APP_CAPLENA_ENV}_${this.user.id}`,
          name: this.user.name,
          email: this.user.email,
          user_hash: this.user.intercom_user_hash
        }
      }
      window.Intercom('boot', intercomSettings)
    },

    intercomLogout () {
      if (window.Intercom) window.Intercom('shutdown')
    },

    /**
     * Close the main messenger panel
     */
    intercomCloseWidget () {
      if (window.Intercom) window.Intercom('hide')
    }
  }
}
