import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

window.language = localStorage.getItem('language') || 'en'

// Ugly hack to get i18n to return arrays, no checks done whatsoever
Vue.prototype.$ta = function (key) {
  return this.$i18n._path.getPathValue(this.$i18n._getMessages()[this.$i18n.locale], key)
}

// Wrapper to capitalize first letter of translated text
Vue.prototype.$tu = function (lookup) {
  let str = this.$t(lookup)
  return str.charAt(0).toUpperCase() + str.substr(1)
}

export default new VueI18n({
  locale: window.language,
  fallbackLocale: '__',
  missing: (locale, key, vm) => {
    let msg = 'MISSING i18n: ' + locale + ' / ' + key
    // If on prod, throw an error.
    // But do it in a timeout, to not break the current execution flow.
    if (process.env.VUE_APP_CAPLENA_ENV === 'prod') setTimeout(() => { throw Error(msg) })
    console.warn(msg)
  },
  // silentTranslationWarn: true
  silentFallbackWarn: true
})
