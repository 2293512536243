import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'

function getParentWithMatchingSelector (target, selector) {
  let matches = [...document.querySelectorAll(selector)].filter(el =>
    el !== target && el.contains(target)
  )
  return (matches.length ? matches[0] : undefined)
}

function getElementForListener (el) {
  /*
    If the element is part of a dialog, attach the listener to the dialog,
    because dialogs stop propagation of click events
    Otherwise attach to app container
   */
  return getParentWithMatchingSelector(el, '.v-dialog') || document.querySelector('[data-app]') || document.body
}

export const clickOutsideDirective = {
  inserted: function (el, binding, vnode) {
    // Make sure that events where the mousedown event is fired from *within* the element do not count
    // Happens when user selects something for example, and mouse ends outside the element

    let mouseWasDownOnEl = false
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and its children
      if (!mouseWasDownOnEl && !(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
      mouseWasDownOnEl = false
    }

    el.mouseDownEvent = function (event) {
      if (el === event.target || el.contains(event.target)) mouseWasDownOnEl = true
      else mouseWasDownOnEl = false
    }

    el.attachor = getElementForListener(el)
    el.attachor.addEventListener('mousedown', el.mouseDownEvent)
    el.attachor.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    el.attachor.removeEventListener('click', el.clickOutsideEvent)
    el.attachor.removeEventListener('mousedown', el.mouseDownEvent)
  }
}

/**
 * Directive which listens for input events and updates the given expression lazily (debounced)
 */
const instantlySetValOnKeypress = ['enter', 'escape']
const defaultDelay = 250
export const lazyModelDirective = {
  twoWay: true,
  bind: function (el, binding, vnode) {
    // Get the Vue component which we directive is bound to
    const component = vnode.componentInstance

    // The argument is the number of milliseconds
    // that the setting function is debounced by
    const delay = binding.arg ? _.toNumber(binding.arg) : defaultDelay

    if (_.isNaN(delay)) {
      throw Error(
        `Got invalid delay in lazyModelDirective: ${binding.arg} (expression ${binding.expression})`
      )
    }

    // This variable should always have the actual value
    // but as it's not part of the normal data model, this shouldn't trigger any direct update
    let instantVal
    let isDirty = false

    if (_.get(vnode.context, binding.value) === undefined) {
      throw Error(`Unknown data value for v-lazy-model: ${binding.value} (expression ${binding.expression}). Remember to pass path as string, e.g. 'codeByID.12345.value', *not* as value like in v-model.`)
    }

    // Lazy update function, taking result of input event and assigning it to
    // binding on parent component. Also sets intantVal
    let debouncedUpdateFn = _.debounce((val) => {
      if (isDirty) _.set(vnode.context, binding.value, val)
      isDirty = false
    }, 250)

    // Add the event listener for the input change
    component.$on('input', (val) => {
      instantVal = val
      isDirty = true
      debouncedUpdateFn(val)
    })

    let instantlySetVal = ($evt) => {
      if (instantVal && isDirty) _.set(vnode.context, binding.value, instantVal)
      isDirty = false
    }

    // When the component is blurred or the enter or escape key are pressed
    // we instantly assign the current value, if there is one
    component.$on('blur', instantlySetVal)
    component.$on('keydown', ($evt) => {
      if (instantlySetValOnKeypress.indexOf($evt.key.toLowerCase()) !== -1) instantlySetVal()
    })
  },

  unbind: function (el) {
    // Nothing to do here
  }
}

export function defineVueDirectives () {
  // Don't name it click-outside, this would infere with the vuetify.js click-outside directive
  Vue.directive('c-click-outside', clickOutsideDirective)
  Vue.directive('lazy-model', lazyModelDirective)
  Vue.directive('observe-visibility', ObserveVisibility)
}
