import * as Sentry from '@sentry/browser'
import axios from 'axios'
import hosts from '../build/hosts'

// Define the global variables
window.API_HOST = hosts.API_HOSTS[process.env.VUE_APP_CAPLENA_ENV]
window.API_HTTP_BASE_URI = hosts.API_HTTP_BASE_URIS[process.env.VUE_APP_CAPLENA_ENV]
window.API_WS_BASE_URI = hosts.API_WS_BASE_URIS[process.env.VUE_APP_CAPLENA_ENV]

const STATUS_JSON_URI = 'https://s3.eu-central-1.amazonaws.com/codit-public/status.json'
const MAX_MISSED_ACKS_BEFORE_ERR = 3

let API = axios.create({
  baseURL: window.API_HTTP_BASE_URI,
  timeout: 150000,
  headers: {
    'Content-Type': 'application/json',
    'Accept-language': ''
  },
  validateStatus: function (status) {
    return status >= 200 && status <= 400
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  withCredentials: true
})

API.interceptors.request.use(
  function (config) {
    config.headers['Accept-language'] = window.language
    return config
  }
)

function maybeDoReport (response) {
  if (response === undefined || response.status === undefined || response.status >= 400) {
    let dontReport = !response || !response.config || response.config.dontReport
    if (!dontReport || (dontReport !== true && dontReport.indexOf(response.status) === -1)) {
      // This error is now "allowed" to be, i.e. it is not expected by the application logic
      // like a 400 sometimes is
      if (response.status === 401 || response.status === 403) {
        window.app.$root.on403()
        response.apiErrReported = true
      } else if (response.status === 404) {
        window.app.$root.on404()
        response.apiErrReported = true
      } else {
        // Overly verbose option to capture extra data with new client
        // See https://github.com/getsentry/sentry-javascript/issues/1607
        Sentry.withScope(scope => {
          scope.setExtra('response', response)
          Sentry.captureException(new Error('Invalid API response'))
        })
        response.apiErrReported = true
      }
      let err = new Error(`Invalid API response: ${response && response.status ? `Status ${response.status}` : response}`)
      err.response = response
      throw err
    }
  }
}

API.interceptors.response.use(
  function (response) {
    maybeDoReport(response)
    return response
  },
  (error) => {
    maybeDoReport(error.response)
    return Promise.reject(error)
  }
)

class WSFactory {
  open (path) {
    return new Promise((resolve, reject) => {
      let connectionID = `cid=${Math.random().toString(36).substr(2, 9)}`
      // The actual connection object
      let socket = new WebSocket(`${window.API_WS_BASE_URI}/${path}?${connectionID}`)
      // Will be populated with error on onError
      let error = null
      let acksMissed = 0

      // Resolve and return socket when connection has been opened
      socket.onopen = function () {
        socket.connectionID = connectionID
        resolve(socket)
      }

      // On connection close, check if an error might have occurred
      // If yes, generate message which is as meaningful as possible and reject
      socket.onclose = function (event) {
        if (!event.wasClean || error) {
          let err = new Error(`Status code=${event.code}, reason=${event.reason}, error=${error}`)
          reject({ err, event }) // eslint-disable-line prefer-promise-reject-errors
        }
      }
      socket.onerror = function (err) {
        // This error doesn't actually tell su a lot, rather fire the error on the onclose event
        error = err
      }

      socket.onmessage = function (event) {
        // Check if the message is an acknowledge message, as response from our heartbeats
        // If yes, reset the counter of acks that are missed
        // If not, parse the response (we always expect json) and pass it to the application level
        // message parsing function (if it is defined)
        if (event.data === '__ack__') {
          acksMissed = 0
          if (_.isFunction(socket.onappheartbeat)) socket.onappheartbeat()
        } else if (_.isFunction(socket.onappmessage)) socket.onappmessage(JSON.parse(event.data))
        else throw Error('Socket expectes `onappmessage` listener to be defined.')
      }

      // Create heartbeat interval
      let interval = setInterval(() => {
        // If we're still connecting, wait
        if (socket.readyState === WebSocket.CONNECTING) return
        // If the socket is open, send the heartbeat
        if (socket.readyState === WebSocket.OPEN) {
          console.debug(`Socket ${path}?${connectionID} beating`)
          socket.send('__hb__')
          // Count the number of acknowledge messages missed
          // If too many are missed, this indicates a broken connection
          // Close the socket then
          if (acksMissed >= MAX_MISSED_ACKS_BEFORE_ERR) {
            console.error(`Missed ${acksMissed} ack messages from websocket. Closing connection`)
            clearInterval(interval)
            socket.close()
          }
          acksMissed++
        } else clearInterval(interval) // Otherwise, something went wrong, remove the heartbeat interval
      }, 5000)
    })
  }
}

const WS = new WSFactory()

export { API, WS, STATUS_JSON_URI }
